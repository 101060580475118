<template>
  <div>
    <div class="row">
      <template
        v-if="
          currentUser.role_key_name != 'super_admin' &&
          currentUser.user_type_id != 3
        "
      >
        <div class="col-12">
          <div class="card">
            <div class="card-body pl-3" v-if="dataLoaded">
              <h6>{{ currentUser.posyandu_name }}</h6>
              <div class="row align-items-center">
                <div class="col-6">
                  <apexchart
                    width="300"
                    height="350"
                    type="bar"
                    :options="options"
                    :series="series"
                  ></apexchart>
                </div>
                <div class="col pl-0">
                  <apexchart
                    width="350"
                    height="350"
                    type="donut"
                    :series="chartSeries"
                    :options="chartOptions"
                  ></apexchart>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
      <template
        v-if="
          currentUser.role_key_name == 'super_admin' ||
          currentUser.user_type_id == 3
        "
      >
        <template v-for="(item, i) in posyandu">
          <div class="col-12" :key="item.id">
            <div class="card">
              <div class="card-body pl-3" v-if="dataLoaded">
                <h6>{{ item.name }}</h6>
                <div class="row align-items-center">
                  <div class="col-6">
                    <apexchart
                      width="300"
                      height="350"
                      type="bar"
                      :options="options"
                      :series="seriesSpecial[i]"
                    ></apexchart>
                  </div>
                  <div class="col pl-0">
                    <apexchart
                      width="350"
                      height="350"
                      type="donut"
                      :series="chartSeriesSpesial[i][0]"
                      :options="chartOptions"
                    ></apexchart>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </template>
      </template>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import module from "@/core/modules/CrudModule.js";
import { getUser, setUser } from "@/core/services/jwt.service.js";

export default {
  data() {
    return {
      dataLoaded: false,
      currentUser: getUser(),
      posyandu: [],
      options: {
        chart: {
          id: "vuechart-example",
        },
        labels: ["0 - 5 Bln", "6 - 11 Bln", "12 - 23 Bln", "24 - 59 Bln"],
        xaxis: {
          categories: ["0 - 5 Bln", "6 - 11 Bln", "12 - 23 Bln", "24 - 59 Bln"],
        },
      },
      series: [
        {
          name: "Jumlah balita",
          data: [30, 40, 20, 10],
        },
      ],
      seriesSpecial: [],
      seriesSpecialForm: {
        name: "Jumlah Balita",
        data: [],
      },
      //   pie
      chartSeries: [44, 55],
      chartOptions: {
        labels: ["Laki-laki", "Perempuan"],
      },
      chartSeriesSpesial: [],
      chartSeriesSpesialForm: [],
    };
  },
  methods: {
    async getByAgeCategory() {
      let total_toddler = await module.getTotalToddler(
        "api/dashboards/total-toddlers/age-category/" + getUser().posyandu_id
      );
      // If Data Not Found
      if (total_toddler == null) {
        // Redirect To List
      } else {
        console.log("age toddler", total_toddler);
        this.series[0].data = total_toddler;
        this.dataLoaded = true;
      }
    },
    async getByGender() {
      let total_toddler = await module.getTotalToddler(
        "api/dashboards/total-toddlers/gender/" + getUser().posyandu_id
      );
      // If Data Not Found
      if (total_toddler == null) {
        // Redirect To List
      } else {
        console.log("gender toddler", total_toddler);
        this.chartSeries = total_toddler;
      }
    },
    async getPosyandu() {
      this.posyandu = await module.getWithoutNumber("api/posyandu");
      // If Data Not Found
      if (this.posyandu == null) {
        // Redirect To List
      } else {
        console.log("posyandu", this.posyandu);
        for (let a = 0; a < this.posyandu.length; a++) {
          let toddler_age = await module.getTotalToddler(
            "api/dashboards/total-toddlers/age-category/" + this.posyandu[a].id
          );

          if (toddler_age != null) {
            console.log("toddler age", toddler_age);
            this.seriesSpecialForm.name = "Jumlah balita";
            this.seriesSpecialForm.data = toddler_age;
            let cloneSeries = { ...this.seriesSpecialForm };
            let arrSeries = [cloneSeries];
            this.seriesSpecial.push(arrSeries);
            console.log("series special", this.seriesSpecial);
            // this.seriesSpecialForm.name = "";
            // this.seriesSpecialForm.data = [];
          }

          let toddler_gender = await module.getTotalToddler(
            "api/dashboards/total-toddlers/gender/" + this.posyandu[a].id
          );
          if (toddler_age != null) {
              this.chartSeriesSpesialForm = toddler_gender
            //   let cloneChart = { ...this.chartSeriesSpesialForm };
              let arrChart = [this.chartSeriesSpesialForm]
              this.chartSeriesSpesial.push(arrChart)
              console.log("chart special", this.chartSeriesSpesial);
          }


          if (
            this.posyandu[this.posyandu.length - 1].id == this.posyandu[a].id
          ) {
            console.log("series special", this.seriesSpecial);
            this.dataLoaded = true;
          }
        }
      }
    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Beranda" }]);
    if (
      getUser().role_key_name != "super_admin" &&
      getUser().user_type_id != 3
    ) {
      this.getByAgeCategory();
      //   this.getByGender()
    } else {
      this.getPosyandu();
    }
  },
};
</script>

<style>

@media (max-width: 767.98px) { 
  .apexcharts-canvas{
    height: 500px !important;
    width: 300px !important;
    }

}
</style>